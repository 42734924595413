.site-header,
.sticky-nav {
    position: relative;
    height: $height_nav;
    background-color: white;
    z-index: 9001;

    @at-root body[data-contrast-mode=on] & {
        background-color: black;
    }

    .wrapper {
        @include maxwidth();
        display: flex;
        height: 100%;
        padding-right: 0;
        overflow-x: clip;

        @include accessibility($breakpoint_to_desktop_nav) {
            overflow-x: initial;
        }

        .logo {
            flex: 0 1 auto;
            @include scale(height, 3.25rem, 20rem, 5.5625rem, 30rem);
            margin: auto auto auto 0;

            svg {
                display: block;
                width: auto;
                height: 100%;

                @at-root body[data-contrast-mode=on] & {
                    path,
                    polygon,
                    polyline {
                        fill: white;
                    }
                }
            }
        }

        .site-nav {
            flex: 0 1 auto;
            position: relative;
            display: flex;
            min-width: 5.5rem;
            max-width: 5.5rem;
            z-index: 100;

            @include accessibility($breakpoint_to_wider_site_nav) {
                min-width: 30rem;
                max-width: 30rem;
            }

            @include accessibility($breakpoint_to_desktop_nav) {
                min-width: auto;
                max-width: none;
            }
        }

        .site-nav .toggle.menu.on svg,
        .site-nav .toggle.menu:hover svg,
        .site-nav .toggle.menu:focus svg {
            fill: white;
        }

        .site-menu {
            position: absolute;
            top: calc(100% - 2rem);
            right: $padding_content;
            width: calc(100vw - 3rem);
            max-width: 30rem;
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 2px;
            box-shadow: 6px 8px 10px 0 rgba(0, 0, 0, .24);
            z-index: 110;
            transform: translateX(calc(100% + 2rem));

            @at-root body[data-contrast-mode=on] & {
                background-color: black;
                border-color: white;
            }

            &::after {
                content: '';
                position: absolute;
                top: -.4375rem;
                right: .6875rem;
                width: .75rem;
                height: .75rem;
                background-color: white;
                border-top: 1px solid #ccc;
                border-left: 1px solid #ccc;
                transform: translateX(-50%) rotate(45deg) skew(-6deg, -6deg);

                @at-root body[data-contrast-mode=on] & {
                    background-color: black;
                    border-top: 2px solid white;
                    border-left: 2px solid white;
                }
            }

            @media (prefers-reduced-motion: no-preference) {
                transition: transform .25s ease-in-out;
            }

            &.is-shown {
                transform: translateX(0);
            }

            @include accessibility($breakpoint_to_desktop_nav) {
                flex: 0 1 auto;
                position: relative;
                top: auto;
                right: auto;
                display: flex !important;
                align-items: center;
                width: auto;
                background-color: transparent;
                box-shadow: none;
                overflow: visible !important;
                transform: translateX(0);
                border: 0;
                border-radius: 0;

                transition: none;

                &::after {
                    display: none;
                }
            }

            // Root
            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                @include accessibility($breakpoint_to_desktop_nav) {
                    display: flex;
                }

                li {
                    position: relative;
                    display: block;
                    margin: 0;

                    @at-root body[data-contrast-mode=on] & {
                        &::after {
                            @include accessibility($breakpoint_to_desktop_nav) {
                                background-color: black;
                                border-top: 2px solid white;
                                border-left: 2px solid white;
                            }
                        }
                    }

                    @include accessibility($breakpoint_to_desktop_nav) {
                        &::after {
                            content: '';
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            width: .75rem;
                            height: .75rem;
                            background-color: white;
                            border-top: 1px solid #ccc;
                            border-left: 1px solid #ccc;
                            transform: translateX(-50%) rotate(45deg) skew(-6deg, -6deg);
                            opacity: 0;
                            visibility: hidden;

                            @media (prefers-reduced-motion: no-preference) {
                                transition: opacity .25s ease-in-out;
                            }
                        }

                        &.on {
                            color: $color_secondary;

                            &::after {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }

                    &.utility {
                        @include accessibility($breakpoint_to_desktop_nav) {
                            display: none !important;
                        }

                        a {
                            color: $color_primary;

                            @at-root body[data-contrast-mode=on] & {
                                color: white;
                            }

                            &:hover,
                            &:focus {
                                color: $color_tertiary;

                                @at-root body[data-contrast-mode=on] & {
                                    color: white;
                                }
                            }
                        }

                        .form-wrapper form {
                            width: 100%;

                            input {
                                flex: 1 1 auto;
                                margin-right: 0;
                                border-right: 0;
                                border-bottom: 0;
                                border-left: 0;
                                border-radius: 0 0 0 2px;
                            }

                            button {
                                border-radius: 0 0 2px 0;
                            }
                        }
                    }

                    &.has-children {
                        display: flex;
                        flex-wrap: wrap;

                        & > a:not(.toggle) {
                            flex: 1 1 auto;
                            display: flex;
                            align-items: center;
                            width: calc(100% - 3rem);
                            max-width: calc(100% - 3rem);
                            color: $color_quaternary;

                            @at-root body[data-contrast-mode=on] & {
                                color: white;
                            }

                            > svg {
                                width: 1.25rem;
                                height: 1.25rem;
                                margin-right: .5rem;

                                path {
                                    fill: currentColor;
                                }
                            }

                            .chevron {
                                display: none;
                            }

                            &:hover,
                            &:focus {
                                color: $color_secondary;
                            }

                            @include accessibility($breakpoint_to_desktop_nav) {
                                width: 100%;
                                max-width: 100%;

                                .chevron {
                                    display: block;
                                    width: .6875rem;
                                    height: .4375rem;
                                    margin-left: .375rem;

                                    svg {
                                        display: block;
                                        width: 100%;
                                        height: 100%;

                                        path,
                                        polyline {
                                            stroke: currentColor;
                                        }
                                    }
                                }
                            }
                        }

                        a.toggle {
                            flex: 1 1 auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 3rem;
                            max-width: 3rem;
                            padding: 0;
                            color: $color_primary;
                            cursor: pointer;

                            @at-root body[data-contrast-mode=on] & {
                                color: white;
                            }

                            @media (prefers-reduced-motion: no-preference) {
                                transition: color .25s ease-in-out;
                            }

                            @include accessibility($breakpoint_to_desktop_nav) {
                                display: none;
                            }

                            svg {
                                flex: 1 1 auto;
                                width: 1.125rem;
                                height: 1.125rem;

                                @media (prefers-reduced-motion: no-preference) {
                                    transition: all .25s ease-in-out;
                                }

                                path {
                                    fill: currentColor;
                                }
                            }

                            &:hover,
                            &:focus {
                                color: $color_tertiary;
                            }
                        }

                        // Children
                        .link-wrapper {
                            flex: 1 1 100%;
                            display: none;

                            @include accessibility($breakpoint_to_desktop_nav) {
                                position: absolute;
                                top: 100%;
                                left: -6rem;
                                display: block !important;
                                padding-top: .375rem;
                                width: 42.5rem;
                                opacity: 0;
                                visibility: hidden;

                                @media (prefers-reduced-motion: no-preference) {
                                    transition: opacity .25s ease-in-out;
                                }

                                &.is-shown {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }

                            ul {
                                @at-root body[data-contrast-mode=on] & {
                                    @include accessibility($breakpoint_to_desktop_nav) {
                                        background-color: black;
                                        border-color: white;
                                    }
                                }

                                @include accessibility($breakpoint_to_desktop_nav) {
                                    column-count: 2;
                                    column-gap: 4rem;
                                    display: block;
                                    padding: 1.5rem 3rem 0;
                                    background-color: white;
                                    border: 1px solid #ccc;
                                    border-radius: 2px;
                                    box-shadow: 6px 8px 10px 0 rgba(0, 0, 0, .24);
                                }

                                li {
                                    break-inside: avoid;
                                    position: relative;
                                    display: flex;

                                    a:not(.toggle) {
                                        flex: 1 1 auto;
                                        display: flex;
                                        padding-left: 2.6875rem;
                                        color: $color_primary;
                                        font-weight: 400;

                                        @at-root body[data-contrast-mode=on] & {
                                            color: white;
                                        }

                                        @include accessibility($breakpoint_to_desktop_nav) {
                                            width: 100%;
                                            margin-bottom: 1.5rem;
                                            padding: 0;
                                        }

                                        .icon {
                                            flex: 0 0 1.25rem;
                                            min-width: 1.25rem;
                                            max-width: 1.25rem;
                                            margin-right: 1rem;

                                            svg {
                                                width: 1.25rem;
                                                height: 1.25rem;

                                                path,
                                                polygon,
                                                rect {
                                                    fill: currentColor;
                                                }
                                            }
                                        }

                                        .copy {
                                            flex: 1 1 auto;
                                            white-space: normal;

                                            header {
                                                color: currentColor;
                                            }

                                            p {
                                                margin: .125rem 0 0;
                                                color: #757575;
                                                font-size: .75rem;
                                                line-height: 1.166666666666667;

                                                @at-root body[data-contrast-mode=on] & {
                                                    color: white;
                                                }
                                            }
                                        }

                                        &:hover,
                                        &:focus {
                                            color: $color_tertiary;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    a {
                        display: block;
                        padding: .75rem 1.375rem;
                        color: $color_primary;
                        font-weight: 700;
                        text-decoration: none;

                        &:hover,
                        &:focus {
                            color: $color_tertiary;
                        }

                        @include accessibility($breakpoint_to_desktop_nav) {
                            padding: .75rem .75rem;
                            white-space: nowrap;

                            &:hover,
                            &:focus {
                                background-color: transparent;
                            }
                        }
                    }

                    &.has-children.on > a.toggle svg {
                        transform-origin: 50% 50%;
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .utility-menu {
            flex: 1 1 auto;
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 1.375rem 0;
            background-image: linear-gradient(180deg, #1b47a3 0%, #236fc0 100%);

            @at-root body[data-contrast-mode=on] & {
                background-color: black;
                background-image: none;
            }

            @include accessibility($breakpoint_to_desktop_nav) {
                width: 30rem;
                margin-left: 3rem;
                padding: 0;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: calc(100% - 3px); // default (Chrome)
                width: 3.5rem;
                height: 100%;
                background-image: url(/dist/svgs/hero-angles.svg), linear-gradient(180deg, #1b47a3 0%, #236fc0 100%);
                background-position: 100% 0%;
                z-index: 3;
                overflow: hidden;

                /* Firefox only */
                @supports (-moz-appearance:none) {
                    right: calc(100% - 2px);
                }

                /* Safari only */
                @media not all and (min-resolution:.001dpcm) {
                    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                        right: calc(100% - 2px);
                    }
                }

                @at-root body[data-contrast-mode=on] & {
                    background-image: url(/dist/svgs/hero-angles-contrast.svg);
                }
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: calc(100% - 1px);
                bottom: 0;
                width: calc(((100vw - #{$max_width_content}) / 2) + 3rem);
                background-image: linear-gradient(180deg, #1b47a3 0%, #236fc0 100%);

                @at-root body[data-contrast-mode=on] & {
                    background-color: black;
                    background-image: none;
                }
            }

            .toggle.menu {
                display: block;
                margin: 0 1rem;
                padding: .6875rem;
                text-decoration: none;
                cursor: pointer;

                @include accessibility($breakpoint_to_desktop_nav) {
                    display: none;
                }

                svg {
                    fill: white;
                    display: block;
                    width: 1.625rem;
                    height: 1.625rem;

                    @media (prefers-reduced-motion: no-preference) {
                        transition: all .25s ease-in-out;
                    }
                }
            }

            ul {
                list-style: none;
                position: relative;
                display: none;
                margin: 0;
                padding: 0;
                z-index: 4;

                @include accessibility($breakpoint_to_desktop_nav) {
                    display: flex;
                    align-items: center;
                }

                li {
                    display: flex;
                    align-items: center;
                    margin: 0 .875rem;

                    a {
                        position: relative;
                        color: white;
                        font-weight: 700;
                        text-decoration: none;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 0;
                            border-bottom: 1px solid currentColor;

                            @media (prefers-reduced-motion: no-preference) {
                                transition: width .25s ease-in-out;
                            }
                        }

                        &:hover,
                        &:focus {
                            &::before {
                                width: 100%;
                            }
                        }
                    }

                    &.search-wrapper {
                        position: relative;

                        a {
                            display: inline-flex;
                            align-items: center;
                            padding: 0;
                            color: white;

                            &::after {
                                content: '';
                                position: absolute;
                                top: calc(100% + .75rem);
                                left: 50%;
                                width: 0 !important;
                                border-right: .5rem solid transparent;
                                border-bottom: .375rem solid white;
                                border-left: .5rem solid transparent;
                                transform: translateX(-50%);
                                opacity: 0;
                                visibility: hidden;

                                @at-root body[data-contrast-mode=on] & {
                                    border-bottom-color: black;
                                }

                                @media (prefers-reduced-motion: no-preference) {
                                    transition: opacity .25s ease-in-out;
                                }
                            }

                            svg {
                                width: 1.25rem;
                                height: 1.25rem;
                                // margin-right: .5rem;

                                path {
                                    fill: currentColor;
                                }
                            }

                            &.show-search {
                                &::after,
                                + .form-wrapper {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }

                        .form-wrapper {
                            position: absolute;
                            top: calc(100% + 1.125rem);
                            left: -1.5rem;
                            padding: 1rem 1.5rem;
                            background-color: white;
                            box-shadow: 6px 8px 10px 0 rgba(0, 0, 0, .24);
                            border-radius: 2px;
                            opacity: 0;
                            visibility: hidden;

                            @at-root body[data-contrast-mode=on] & {
                                background-color: black;
                                border: 1px solid white;
                            }

                            @media (prefers-reduced-motion: no-preference) {
                                transition: opacity .25s ease-in-out;
                            }

                            form {
                                input {
                                    width: 90vw;
                                    max-width: 20rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.sticky-nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: #{$height_nav - 3};
    box-shadow: 6px 8px 10px 0 rgba(0, 0, 0, 0);
    transform: translateY(-100%);

    @media (prefers-reduced-motion: no-preference) {
        transition: all .5s ease-in-out;
    }

    &[aria-hidden=true] {
        visibility: hidden;

        .site-menu {
            display: none;
        }
    }

    &[aria-hidden=false] {
        box-shadow: 6px 8px 10px 0 rgba(0, 0, 0, .24);
        visibility: visible;
        transform: translateY(0);

        @at-root body[data-contrast-mode=on] & {
            box-shadow: 6px 8px 10px 0 rgba(255, 255, 255, .24);
        }
    }

    .wrapper {
        padding-right: 0;

        .logo {
            height: 70% !important;
        }

        .site-menu {
            top: calc(100% - .5rem);
            max-height: calc(100vh - 5.5rem);
            overflow-y: auto;

            @include accessibility($breakpoint_to_desktop_nav) {
                top: auto;
            }
        }
    }
}
