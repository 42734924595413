article.homepage {
    position: relative;

    .plane-scroll {
        position: fixed;
        top: 25vh;
        left: 50%;
        width: 4rem;
        height: 4rem;
        z-index: -1;
        transform: translateX(-50%);

        svg {
            display: block;
            width: 100%;
            height: 100%;

            @at-root body[data-contrast-mode=on] & {
                path {
                    fill: white;
                }
            }
        }
    }
}

.home-banner {
    position: relative;
    overflow: hidden;

    .copy {
        @include maxwidth();
        position: relative;
        display: flex;
        justify-content: flex-start;
        padding: 4rem 1rem;
        z-index: 2;

        .wrapper {
            width: 100%;
            max-width: 33.75rem;

            @at-root body[data-contrast-mode=on] & {
                background-color: black;
            }

            .flight-finder {
                .h2 {
                    max-width: 23.75rem;
                    padding: 0 1rem;
                    text-shadow: 1px 1px 3px white;

                    @at-root body[data-contrast-mode=on] & {
                        text-shadow: none;
                    }
                }

                .tabs {
                    border-bottom: 0;

                    a {
                        margin: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:first-child {
                            border-radius: 4px 0 0 0;
                        }

                        &:last-child {
                            border-radius: 0 4px 0 0;
                        }

                        svg {
                            width: 2.625rem;
                            height: 1.5rem;
                            margin-right: .5rem;

                            path {
                                fill: currentColor;
                            }
                        }
                    }
                }

                .tab-content {
                    $breakpoint_to_flex : 28rem;
                    padding-top: 1.75rem;
                    @include scale(padding-right, 1.5rem, 20rem, 2.625rem, $breakpoint_to_flex);
                    padding-bottom: 1.75rem;
                    @include scale(padding-left, 1.5rem, 20rem, 2.625rem, $breakpoint_to_flex);
                    background-color: white;
                    border: 1px solid #efefef;
                    box-shadow: 10px 12px 24px 0 rgba(black, .10);
                    border-radius: 2px;

                    @at-root body[data-contrast-mode=on] & {
                        background-color: black;
                        border-color: white;
                    }

                    p {
                        text-align: center;
                    }

                    form {
                        @include accessibility($breakpoint_to_flex) {
                            display: flex;
                        }

                        input {
                            width: 100%;
                            margin-bottom: .375rem;
                            padding: 1.0625rem .75rem;
                            font-size: .75rem;
                            border: 1px solid #ededed;
                            border-radius: 2px;

                            @at-root body[data-contrast-mode=on] & {
                                color: white;
                                background-color: black;
                                border-color: white;
                            }

                            @include accessibility($breakpoint_to_flex) {
                                flex: 1 1 auto;
                                width: auto;
                                margin-right: .375rem;
                                margin-bottom: 0;
                            }

                            &::placeholder {
                                font-style: italic;

                                @at-root body[data-contrast-mode=on] & {
                                    color: white;
                                }
                            }
                        }

                        button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            padding: .75rem 1.75rem;
                            color: white;
                            background-color: $color_primary;
                            border: 0;
                            border-radius: 2px;

                            @at-root body[data-contrast-mode=on] & {
                                background-color: black;
                                border: 1px solid white;
                            }

                            @include accessibility($breakpoint_to_flex) {
                                flex: 0 0 auto;
                                width: auto;
                            }

                            svg path {
                                fill: currentColor;
                            }
                        }
                    }
                }
            }

            .featured-links {
                $breakpoint_to_row : 36rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 2.25rem;
                padding: 1rem;
                background-color: white;
                border-radius: 2px;

                @at-root body[data-contrast-mode=on] & {
                    background-color: black;
                }

                @include accessibility($breakpoint_to_row) {
                    flex-direction: row;
                }

                a {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: .875rem;
                    line-height: 1.2;

                    &:not(:last-of-type) {
                        margin-bottom: 1rem;
                        padding-bottom: 1rem;

                        @include accessibility($breakpoint_to_row) {
                            margin-right: 1rem;
                            margin-bottom: 0;
                            padding-right: 1rem;
                            padding-bottom: 0;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            width: 3.5rem;
                            border-bottom: 1px solid #ccc;
                            transform: translateX(-50%);

                            @include accessibility($breakpoint_to_row) {
                                top: 50%;
                                right: 0;
                                bottom: auto;
                                left: auto;
                                width: 0;
                                height: 3.5rem;
                                border-right: 1px solid #ccc;
                                border-bottom: 0;
                                transform: translateY(-50%);
                            }
                        }
                    }

                    .icon {
                        width: 2.1875rem;
                        height: 2.1875rem;
                        margin-bottom: .75rem;

                        svg,
                        picture {
                            width: 100%;
                            height: auto;

                            ellipse,
                            path,
                            rect {
                                fill: currentColor;
                            }
                        }
                    }
                }
            }
        }
    }

    .img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;

        @include accessibility($breakpoint_to_wider_site_nav) {
            width: 43.375rem;
        }

        @include accessibility($breakpoint_to_desktop_nav + (2 * $padding_content)) {
            width: calc(50vw + 4.875rem);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 6.9375rem;
            width: 100%;
            height: 60rem;
            background: transparent url(/dist/svgs/hero-angles.svg) 100% 50% no-repeat scroll;
            background-size: auto 100%;
            z-index: 3;

            @at-root body[data-contrast-mode=on] & {
                background-image: url(/dist/svgs/hero-angles-contrast.svg);
            }

            @include accessibility($breakpoint_to_wider_site_nav) {
                right: 31.4375rem;
            }

            @include accessibility($breakpoint_to_desktop_nav) {
                right: calc((30rem + 23px) + ((100vw - #{$max_width_content}) / 2));
            }

            @include accessibility($breakpoint_to_desktop_nav + (2 * $padding_content)) {
                right: calc(100% - 11.9375rem);
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 40%;
            background-image: linear-gradient(180deg, #236fc0 0%, rgba(#236fc0, 0) 100%);
            z-index: 2;

            @at-root body[data-contrast-mode=on] & {
                display: none;
            }
        }

        picture {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.compare-travel-costs {
    margin: 0;
    padding: 1.5rem 0;
    background-image: linear-gradient(2deg, $color_primary 0%, $color_tertiary 100%);

    @at-root body[data-contrast-mode=on] & {
        color: white;
        background-color: black;
        background-image: none;
        border-bottom: 1px solid white;
    }

    .wrapper {
        $breakpoint_to_horizontal : 50rem;
        @include maxwidth();
        color: white;

        @include accessibility($breakpoint_to_horizontal) {
            display: flex;
        }

        .copy {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .h4 {
                margin: 0;
                color: inherit;
            }

            p {
                margin: .625rem 0 0;
                line-height: 1.3125;
            }
        }

        .sep {
            flex: 0 0 auto;
            margin: 1.5rem 0;
            border-bottom: 1px solid rgba(#ccc, .5);

            @at-root body[data-contrast-mode=on] & {
                border-color: white;
            }

            @include accessibility($breakpoint_to_horizontal) {
                margin: 0 3.75rem;
                border-right: 1px solid rgba(#ccc, .5);
            }
        }

        .form {
            flex: 1 1 auto;

            span {
                display: block;
                margin-bottom: .5rem;
                font-size: .875rem;
            }

            form {
                display: flex;

                input {
                    flex: 1 1 auto;
                    margin-right: .375rem;
                    padding: 1.0625rem .75rem;
                    color: black;
                    font-size: .75rem;
                    border: 1px solid #ededed;
                    border-radius: 2px;

                    @at-root body[data-contrast-mode=on] & {
                        color: white;
                        background-color: black;
                        border-color: white;
                    }

                    &::placeholder {
                        font-style: italic;

                        @at-root body[data-contrast-mode=on] & {
                            color: white;
                        }
                    }
                }

                button {
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: .75rem 1.75rem;
                    color: white;
                    background-color: $color_primary;
                    border: 0;
                    border-radius: 2px;

                    @at-root body[data-contrast-mode=on] & {
                        background-color: black;
                        border: 1px solid white;
                    }

                    svg path {
                        fill: currentColor;
                    }
                }
            }
        }
    }
}