/******************************************************************************
 *	COLORS
 *****************************************************************************/
$color_primary                      : #0077c0;
$color_secondary                    : #445d1b;
$color_tertiary                     : #004376;
$color_quaternary                   : #5f812a;
$color_quinary                      : #fbcf5c;

$colors_social_media : (
	facebook						: #1877f2,
	flickr							: #0063dc,
	google-plus						: #dc4e41,
	instagram						: #e4405f,
	linkedin						: #0077b5,
	medium							: #12100e,
	pinterest						: #bd081c,
	twitter							: #1da1f2,
	vimeo							: #1ab7ea,
	yelp							: #d32323,
	youtube							: #ff0000,
);


/******************************************************************************
 *	FONTS
 *****************************************************************************/
$font_stack_serif					: "Lucida Bright", Georgia, TimesNewRoman, "Times New Roman", Times, Baskerville, serif;
$font_stack_sans_serif				: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font_stack_cursive					: cursive;
$font_stack_fantasy					: fantasy;
$font_stack_monospace				: monospace;

$font_family_roboto 				: 'Roboto', $font_stack_sans_serif;

$font_family_body					: $font_family_roboto;
$font_family_headings				: $font_family_roboto;


/******************************************************************************
 *	BREAKPOINTS
 *****************************************************************************/
$breakpoint_to_sidebar				: 60rem;
$breakpoint_to_desktop_nav			: 77rem;
$breakpoint_to_wider_site_nav       : 52rem;


/******************************************************************************
 *	CONTENT
 *****************************************************************************/
$max_width_content					: 77rem; // Actual max width + 2 x $padding_content (below)
$margin_sections					: 5rem;
$padding_content					: 1rem;

$height_nav                         : 7.75rem;