html {
    scrollbar-gutter: auto;

	@media (prefers-reduced-motion: no-preference) {
		scroll-behavior: smooth;
	}

	#widthREMs { width: $breakpoint_to_desktop_nav; }

	&[data-font-size=lg] {
        font-size: 150%;
    }

	&[data-font-size=xl] {
        font-size: 200%;
    }
}

body {
    width: 100vw;
	color: #383838;
	font-family: $font_stack_sans_serif;
	line-height: 1.5;
	overflow-x: hidden;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	&[data-fonts-loaded=true] {
		font-family: $font_family_body;
	}

    &[data-contrast-mode=on] {
		color: white;
		background: black;
	}

	#loading-overlay {
		display: none;

		div {
			@extend %center;

			.spinner {
				display: block;
				animation: rotator 1.4s linear infinite;

				.path {
					stroke: black;
					stroke-dasharray: 187;
					stroke-dashoffset: 0;
					transform-origin: center;
					animation: dash 1.4s ease-in-out infinite;
				}
			}
		}
	}

	&.is-loading {
		overflow: hidden;
		cursor: wait;

		#loading-overlay {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			background-color: rgba(#fff, .75);
			cursor: wait;
			z-index: 999999;
		}
	}
}

a {
    color: $color_primary;
    font-weight: 700;
    text-decoration: none;

    @at-root body[data-contrast-mode=on] & {
        color: white;
        text-decoration: underline;
    }

    @media (prefers-reduced-motion: no-preference) {
        transition: all .25s ease-in-out;
    }

	&:link {}

	&:visited {}

	&:hover,
	&:focus {
        color: $color_tertiary;
	}

	&:active {}
}

img {
	@include browser(ie11) {
		-ms-interpolation-mode: bicubic;
	}
}

.h1, .h2, .h3, .h4, .h5, .h6 {
	color: $color_tertiary;
    font-weight: 400;
	line-height: 1.2;

	@at-root body[data-fonts-loaded=true] & {
		font-family: $font_family_headings;
	}

    @at-root body[data-contrast-mode=on] & {
        color: white;
    }
}

.h1 {
	@include scale(font-size, 1.75rem, 20rem, 2.25rem, 64rem);
	letter-spacing: .011em;
}

.h2 {
	@include scale(font-size, 1.4375rem, 20rem, 2rem, 64rem);
	letter-spacing: .014em;
}

.h3 {
	@include scale(font-size, 1.5rem, 20rem, 1.75rem, 64rem);
	letter-spacing: .018em;
}

.h4 {
	@include scale(font-size, 1.25rem, 20rem, 1.5rem, 64rem);
	letter-spacing: .021em;
}

.h5 {
	@include scale(font-size, 1.125rem, 20rem, 1.25rem, 64rem);
	letter-spacing: .025em;
}

.h6 {
	@include scale(font-size, 1rem, 20rem, 1.125rem, 64rem);
	letter-spacing: .028em;
}

blockquote {
	@include maxwidth(62rem);
	position: relative;
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
	padding-left: 5.25rem;
	color: inherit;
	font-style: italic;
	font-weight: 400;
	@include scale(font-size, 1.25rem, 20rem, 2rem, 60rem);
	font-family: inherit;
	line-height: 1.34375;

	strong {
		font-weight: 400;
	}

	&::before {
		content: url(/dist/svgs/quote.svg);
		position: absolute;
		top: .5625rem;
		left: 0;

        @at-root body[data-contrast-mode=on] & {
            content: url(/dist/svgs/quote-white.svg);
        }
	}
}

ul, ol {
	li {
		margin-bottom: .5rem;
	}
}

hr {
    @include maxwidth();
    position: relative;
    height: 0 !important;
    margin-top: $margin_sections !important;
    margin-bottom: $margin_sections !important;
    border: 1px solid #e4e4e4 !important;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5.125rem;
        height: 1rem;
        background-color: white;
        transform: translate(-50%, -50%);

        @at-root body[data-contrast-mode=on] & {
            background-color: black;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2.5rem;
        height: 2.5rem;
        background: transparent url(/dist/svgs/airplane.svg) center / 2.5rem no-repeat scroll;
        opacity: .65;
        transform: translate(-50%, -50%);

        @at-root body[data-contrast-mode=on] & {
            background-image: url(/dist/svgs/airplane-white.svg);
        }
    }
}