#alert {
	$color_general								: #7aaecc;
	$color_informational						: #85c169;
	$color_urgent								: #c7031e;
	position: relative;
	display: flex;
	width: 100%;
    color: white;
	line-height: 1.357142857142857;
	background-color: $color_quaternary;

	a:not(.close),
	div {
		flex: 1 1 100%;
		padding: 1rem;
		color: #fff;
		font-weight: 400;
        text-align: center;
		text-decoration: none;

		strong {
			margin-right: .75rem;
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	.close {
		flex: 0 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 1rem;
		text-decoration: none;
		background-color: rgba(black, .24);

		svg {
			display: block;
			width: 1rem;
			height: 1rem;

            path {
			    fill: white;
            }
		}

		&:hover,
		&:focus {
			background-color: rgba(black, .48);
		}
	}

	&.urgent {
		background-color: $color_urgent;
	}

	&.informational {
		background-color: $color_informational;
	}

    @at-root body[data-contrast-mode=on] & {
        background-color: black;
        border-bottom: 1px solid white;

        .close {
            background-color: white;

            svg path {
                fill: black;
            }
        }
    }
}