@import '../../node_modules/normalize.css/normalize';

/* box-sizing */
html {
	box-sizing: border-box;
}

*,
::after,
::before {
	box-sizing: inherit;
}